<!-- System: STA
    Purpose: This compoment will display archived team 
            members table in list view
-->
<template>
  <v-app>
    <!--------------------------  Start Table View ----------------------->
    <template>
      <!-- Archived Team Member Table -->
      <v-simple-table class="mt-4">
        <template v-if="filtered.length > 0" v-slot:default>
          <thead>
            <tr>
              <th class="text-center"></th>
              <th
                class="text-center"
                style="font-size: 17px; cursor: pointer"
                @click="sortData('name')"
              >
                Name <span class="mdi mdi-sort" style="font-size: 22px"></span>
              </th>
              <th
                class="text-center"
                style="font-size: 17px; cursor: pointer"
                @click="sortData('last_working_date')"
              >
                Last Working Date
                <span class="mdi mdi-sort" style="font-size: 22px"></span>
              </th>
              <th
                class="text-center"
                style="font-size: 17px; cursor: pointer"
                @click="sortData('total_hours')"
              >
                Total hours
                <span class="mdi mdi-sort" style="font-size: 22px"></span>
              </th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <!-- Tooltip For Team Member Details -->
            <tr v-for="member in filtered" :key="member.id">
              <td>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      v-if="member.image == null"
                      src="../../assets/images/no-member.jpg"
                      @error="
                        $event.target.src = require('../../assets/images/no-member.svg')
                      "
                      alt="no member"
                      class="member-image m-1024"
                      fluid
                      thumbnail
                    />
                    <img
                      v-bind="attrs"
                      v-on="on"
                      v-else
                      :id="'popover_' + member.id"
                      :hover="(displayPopover = true)"
                      :src="`${url}storage/${member.image}`"
                      @error="
                        $event.target.src = require('../../assets/images/no-member.svg')
                      "
                      alt="Thumbnail"
                      class="member-image m-1024"
                      fluid
                      thumbnail
                    />
                  </template>
                  <span>{{ member.name }}</span>
                </v-tooltip>
              </td>
              <td>{{ member.name }}</td>
              <td>{{ member.last_working_date }}</td>
              <td>
                {{
                  member.total_hours
                    ? convert_mins_to_hour(member.total_hours)
                    : "00:00"
                }}
              </td>
              <!-- Team Member Action Buttons -->
              <td>
                <!-- Restore Member Buttons -->
                <v-icon
                  class="ml-1 icon-color"
                  data-trigger="edit_profile_member"
                  size="23"
                  @click="restore(member)"
                >
                  mdi-restore
                </v-icon>
                <!-- Delete Member Buttons -->
                <v-icon
                  class="ml-1 icon-color"
                  size="23"
                  data-trigger="edit_profile_member"
                  @click="deleteMem(member)"
                >
                  mdi-delete-outline
                </v-icon>
              </td>
            </tr>
          </tbody>
        </template>
        <!-- Render If No Member Found -->
        <template v-else v-slot:default>
          <p class="mt-4" v-if="$store.state.custom.set_active_loader == true">
            {{ "...loading" }}
          </p>
          <p class="mt-4" v-else><NoMemberFound /></p>
        </template>
      </v-simple-table>
    </template>
    <!--------------------------  End Table View ----------------------->
    <!--------------------------  Restore Dailog Box ----------------------->
    <div class="text-center">
      <v-dialog
        v-model="restoreDialog"
        width="500"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-toolbar color="primary" class="headline" dark
            >Are you sure?</v-toolbar
          >
          <v-card-text class="mt-4">
            <h4>
              If you restore the member, all data against member will be
              restored.
            </h4>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined text @click="restoreDialog = false">
              Cancel
            </v-btn>
            <v-btn
              style="
                background-color: #2758f6 !important;
                color: #ffffff !important;
              "
              outlined
              text
              @click="(restoreDialog = false), restoreMember('restore')"
            >
              Restore
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--------------------------  Delete Dailog Box ----------------------->
    <div class="text-center">
      <v-dialog
        v-model="deleteDialog"
        width="500"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-toolbar color="primary" class="headline" dark
            >Are you sure?</v-toolbar
          >
          <v-card-text class="mt-4">
            <h4>
              We are sorry to hear you’d like to delete the member. All the data
              associated with the member will no longer be accessible.
            </h4>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined text @click="deleteDialog = false">
              Cancel
            </v-btn>
            <v-btn
              style="
                background-color: #ff6b6b !important;
                color: #ffffff !important;
              "
              outlined
              text
              @click="(deleteDialog = false), deleteMember('delete')"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--------------------------  End Dailog Box ----------------------->
    <Snackbar
      :snackbar.sync="snackbar"
      :message="message"
      :color="snackbarColor"
    />
  </v-app>
</template>
<script>
import _ from "lodash";
import { min_to_hour } from "@/assets/js/utils";
import Snackbar from "@/components/includes/Snackbar";
import { baseURL } from "@/assets/js/urls";
export default {
  name: "TeamMemberArchiveTable",
  components: {
    Snackbar,
    NoMemberFound: () => import("@/components/includes/errors/NoMemberFound"),
  },
  props: {
    members: {},
    membersToShow: {},
  },
  data() {
    return {
      renderRemainingMembers: false,
      order_by: {
        col: "last_working_date",
        selectedOrder: "desc",
      },
      restoreDialog: false,
      deleteDialog: false,
      restore_member: "",
      deletemember: "",
      message: "",
      snackbarColor: "",
      snackbar: false,
    };
  },
  mounted() {
    this.renderOtherMembers();
  },
  computed: {
    /**
     * Return filterd member
     * according to column
     */
    filtered() {
      let filteredMembers = this.members;
      if (this.order_by.col == "total_hours") {
        filteredMembers = _.orderBy(
          this.members,
          [
            function (o) {
              return +o.total_hours;
            },
          ],
          [this.order_by.selectedOrder]
        );
      }
      if (
        this.order_by.col == "name" ||
        this.order_by.col == "last_working_date"
      ) {
        filteredMembers = _.orderBy(
          this.members,
          [this.order_by.col],
          [this.order_by.selectedOrder]
        );
      }
      return filteredMembers;
    },
    url() {
      return baseURL.API_URL;
    },
  },
  methods: {
    /**
     * This function is resposible for
     * sort column data
     */
    sortData(col) {
      this.order_by.selectedOrder =
        this.order_by.selectedOrder == "desc" ? "asc" : "desc";
      this.order_by.col = col;
    },
    spliter(data) {
      if (data.split(".")[1] === "last_worked_at") {
        return data.split(".")[1];
      } else if (data.split(".")[1] === "name") {
        return data.split(".")[1];
      } else {
        return data.split(".")[0];
      }
    },
    /**
     * This function is resposible for
     * converting mins to hours
     */
    convert_mins_to_hour(totalTime) {
      let time = min_to_hour(totalTime);
      let hour = time.hrs < 10 ? "0" + time.hrs : time.hrs;
      let mins = time.mins < 10 ? "0" + time.mins : time.mins;
      return hour + ":" + mins;
    },
    /**
     * This function is resposible for rendering
     * member after 1500ms in table
     */
    renderOtherMembers() {
      setTimeout(() => {
        this.renderRemainingMembers = true;
      }, 1500);
    },
    /**
     * This function is resposible for trigger
     * restore member modal
     */
    restore(member) {
      this.restoreDialog = true;
      this.restore_member = member;
    },
    /**
     * This function is resposible for trigger
     * delete member modal
     */
    deleteMem(member) {
      this.deleteDialog = true;
      this.deletemember = member;
    },
    /**
     * This function is resposible for
     * delete member  (api call)
     */
    deleteMember() {
      let member = this.deletemember;
      if (member.member_status === "active") return;
      let data = {
        member_id: member.id,
        company_id: this.$route.params.id,
        is_archived: true,
      };
      this.$store.commit("custom/set_loader", true);
      this.$store
        .dispatch("custom/delete_member", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.snackbar = true;
            this.message = "You have successfully deleted a member";
            this.snackbarColor = "green";
            this.$store.commit("custom/toggle_progress_loader", false);
            this.$store.dispatch("custom/get_archived_team_members_list", {
              company_id: this.$route.params.id,
            });
            this.$store.dispatch("custom/get_active_team_members_list", {
              company_id: this.$route.params.id,
            });
          }
        })
        .catch(() => {
          this.$store.commit("custom/toggle_loader", false);
        });
    },
    /**
     * This function is resposible for
     * restore member  (api call)
     */
    restoreMember() {
      let member = this.restore_member;
      console.log("member", member);
      if (member.member_status === "active") return;
      let data = {
        member_id: member.id,
        company_id: this.$route.params.id,
      };
      this.$store.commit("custom/set_loader", true);
      this.$store
        .dispatch("custom/restore_member", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.snackbar = true;
            this.message = "You have successfully restored a member";
            this.snackbarColor = "green";
            this.$store.commit("custom/set_loader", false);
            this.$store.dispatch("custom/get_archived_team_members_list", {
              company_id: this.$route.params.id,
            });
            this.$store.dispatch("custom/get_active_team_members_list", {
              company_id: this.$route.params.id,
            });
          }
        })
        .catch(() => {
          this.$store.commit("custom/toggle_loader", false);
        });
    },
  },
};
</script>
<style scoped>
img.member-image {
  border-radius: 100%;
  max-width: 65px;
  max-height: 40px;
  margin-left: -2px !important;
}
span.v-badge.badge-status.v-badge--dot.theme--light {
  position: relative !important;
  top: -24px !important;
  left: 12px !important;
}
</style>
